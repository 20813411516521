import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit (event) {
    const form = event.currentTarget.closest("form")

    // Add 'data-autosubmit-continue-param="true"' after your data-action to simulate a `submit_and_stay` submit button
    // (which redirects the user to the same page if the Rails controller allows it)
    // https://stimulus.hotwired.dev/reference/actions#action-parameters
    if (event.params.continue) {
      const input = document.createElement('input')
      input.setAttribute('name', 'continue')
      input.setAttribute('value', 'true')
      input.setAttribute('type', 'hidden')
      form.appendChild(input)
    }

    form.requestSubmit()
  }
}
