import { Controller } from "@hotwired/stimulus"
import Macy from 'macy'
import { debounce } from 'lodash-es'

export default class extends Controller {
  static values = {
    options: Object
  }

  static targets = ['container']

  connect () {
    this.#initMacy()
    this.#setupMutationObserver()
  }

  disconnect () {
    this.macy.remove()
    this.macy = undefined
    this.observer.disconnect()
  }

  #initMacy () {
    // Hang a reference to the Stimulus controller instance off the DOM element
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this

    this.macy = new Macy({
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  #setupMutationObserver () {
    const debouncedRecalculate = debounce(() => {
      this.macy.recalculate(true)
    }, 200)

    this.observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        // A child node has been added or removed
        if (mutation.type === "childList") debouncedRecalculate()
      }
    })

    this.observer.observe(this.container, { childList: true })
  }

  get container () {
    return this.hasContainerTarget ? this.containerTarget : this.element
  }

  get defaultOptions () {
    return {
      container: this.container,
      waitForImages: false,
      useOwnImageLoader: false,
      mobileFirst: true,
      columns: 1,
      margin: {
        y: 10,
        x: 10
      },
      breakAt: {
        1200: 3,
        520: 2
      }
    }
  }
}
