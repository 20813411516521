import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'formTypeInput',
    'contactFieldset',
    'documentUploadInput',
    'documentFieldset',
    'displayImageInput',
    'imageFieldset',
    'iconFieldset',
    'documentInput',
    'filename'
  ]

  connect () {
    this.toggleContactFieldset()
    this.toggleDocumentFieldset()
    this.toggleImageOrIconFieldset()
  }

  toggleContactFieldset () {
    if (!this.hasContactFieldsetTarget) return

    const disabled = !(this.formTypeInputTarget.value === "contact")
    this.contactFieldsetTarget.hidden = disabled
    this.contactFieldsetTarget.disabled = disabled
  }

  toggleDocumentFieldset () {
    if (!this.hasDocumentFieldsetTarget) return

    const disabled = this.documentUploadInputTarget.value !== "true"
    this.documentFieldsetTarget.hidden = disabled
    this.documentFieldsetTarget.disabled = disabled
  }

  toggleImageOrIconFieldset () {
    if (this.hasImageFieldsetTarget || this.hasIconFieldsetTarget) {
      const disabled = this.displayImageInputTarget.value !== "true"

      this.imageFieldsetTargets.forEach(input => {
        input.hidden = disabled
        input.disabled = disabled
      })
      this.iconFieldsetTargets.forEach(input => {
        input.hidden = !disabled
        input.disabled = !disabled
      })
    }
  }

  selectFile () {
    this.documentInputTarget.click()
  }

  updateFilename () {
    const file = this.documentInputTarget.files[0]
    this.filenameTarget.textContent = file ? file.name : "Aucun fichier sélectionné"
  }
}
