import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["element"]

  // automatically close the flash message after 5 seconds
  elementTargetConnected(element) {
    setTimeout(() => element.remove(), 5000)
  }

  close(event) {
    event.currentTarget.closest(`[data-${this.identifier}-target='element']`).remove()
  }
}
